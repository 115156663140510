<template>
    <div>
        <div v-if="isViewReady && isReady && isAuthenticated">
            <slot></slot>
        </div>
<!--
        <v-row v-if="!isViewReady || !isReady" justify="center">
            <v-col cols="12">
                <p class="blue--text text-h4 font-weight-light text-center pa-5">Loading...</p>
            </v-col>
        </v-row>
-->
        <v-row justify="center" v-if="isViewReady && isReady && !isAuthenticated" class="pt-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card elevation="6" class="px-4 pt-6 pb-4">
                        <v-row class="text-h6 font-weight-light" justify="center">
                            <p>Returning users, login to continue:</p>
                        </v-row>
                        <v-row justify="center" class="py-2">
                            <v-btn @click="login" tile elevation="6" class="purple white--text">Login</v-btn>
                        </v-row>
                        <v-row class="pt-2 pb-2" justify="center" v-if="loginError">
                            <p class="text-body-1  font-weight-light red--text">Cannot process request</p>
                        </v-row>
                        <v-divider class="mt-4 mx-2"></v-divider>
                        <v-row justify="center" class="pt-4">
                            <p class="text-h6 font-weight-light text-center">Is this your first time at LoginShield?</p>
                        </v-row>
                        <v-row justify="center" class="pb-2">
                            <p class="text-body-1 font-weight-light text-center">You only need an email address to get started:</p>
                        </v-row>
                        <v-row class="pb-2" justify="center">
                            <v-btn @click="createAccount" elevation="6" tile class="purple white--text">Create account</v-btn>
                        </v-row>
                        <v-row class="pt-2" justify="center" v-if="createAccountError">
                            <p class="text-body-1 font-weight-light red--text">Cannot process request</p>
                        </v-row>
                    </v-card>
                </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { Client } from '@/client';

const client = new Client();

export default {
    props: {
        /*
        The parent component might have other work to do before
        deciding whether to show the authenticated or non-authenticated
        views. The LoginRedirect component might redirect the user to
        another page, and needs the progress indicator to show until
        the redirect is ready, instead of showing "login to continue"
        briefly. Most components don't need this, so the default is
        that to assume the parent component is ready and only depend
        on the isReady state from the Vuex store.
         */
        isViewReady: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            loginError: false,
            createAccountError: false,
        };
    },
    methods: {
        async login() {
            const { fullPath } = this.$router.currentRoute;

            // create a new interaction so after login the user will be
            // redirected back to the current page
            const createInteractionResponse = await client.interaction.create({
                type: 'require_login',
                state: {
                    redirect: fullPath,
                },
            });
            if (typeof createInteractionResponse !== 'object' || createInteractionResponse.error || !createInteractionResponse.isCreated || !createInteractionResponse.id) {
                this.loginError = true;
                return;
            }

            this.$router.push({ path: '/login', query: { i: createInteractionResponse.id } });
        },
        async createAccount() {
            const { fullPath } = this.$router.currentRoute;

            // create a new interaction so after login the user will be
            // redirected back to the current page
            const createInteractionResponse = await client.interaction.create({
                type: 'require_login',
                state: {
                    redirect: fullPath,
                },
            });
            if (typeof createInteractionResponse !== 'object' || createInteractionResponse.error || !createInteractionResponse.isCreated || !createInteractionResponse.id) {
                this.createAccountError = true;
                return;
            }

            this.$router.push({ path: '/create-account', query: { i: createInteractionResponse.id } });
        },
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    mounted() {
        console.log('RequireLogin.vue: mounted');
    },
};
</script>
