<template>
    <v-container>
        <RequireLogin>
            <v-snackbar v-model="snackbarRememberDevice" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Personal device setting updated</span>
                <v-btn text class="blue white--text" @click="snackbarRememberDevice = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarIdentityNameUpdated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Name updated</span>
                <v-btn text class="blue white--text" @click="snackbarIdentityNameUpdated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarIdentityEmailUpdated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Email updated</span>
                <v-btn text class="blue white--text" @click="snackbarIdentityEmailUpdated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarLockTimeUpdated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Lock time updated</span>
                <v-btn text class="blue white--text" @click="snackbarLockTimeUpdated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarAccountDeleteFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Failed to delete account</span>
                <v-btn text class="red white--text" @click="snackbarAccountDeleteFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-row justify="center" class="py-5">
                <h1 class="text-h4 font-weight-light">Preferences</h1>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Name</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                    <p>For example, "Sparky" or "Doctor Who"</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableName
                                color="purple"
                                label="Your nickname, first name, or title"
                                :rules="nameRules"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="6" class="blue white--text" @click="editName" :disabled="!isIdentityNameComplete">
                                <font-awesome-icon icon="check" fixed-width/>
                                Save
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Email</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this email address to send you electronic messages.</p>
                                    <!-- <p>If you change this address we will send a verification email and you must click on the link in the email to confirm the change.</p> -->
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableEmail
                                color="purple"
                                label="Your email address"
                                :rules="emailRules"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-row>
                        <!-- <v-row justify="center">
                            <v-btn elevation="6" class="blue white--text" @click="editEmail" :disabled="!isIdentityEmailComplete">
                                <font-awesome-icon icon="check" fixed-width/>
                                Save
                            </v-btn>
                        </v-row> -->
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="px-10 pb-5">
                        <v-card-text class="text-h6 text-center">Lock</v-card-text>
                        <v-divider class="mx-5 mb-10"></v-divider>
                        <v-select :items="sessionExpiresChoices" v-model="sessionIdleExpiresMillis" label="Automatically lock account after..."></v-select>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="6" class="px-10 pb-5" style="border: solid 1px red;">
                        <v-card-text class="text-h6 red--text text-center">Delete Account</v-card-text>
                        <v-divider class="mx-5 mb-10"></v-divider>
                        <v-row justify="center">
                            <p class="text-body-1 font-weight-light pb-2">All account data will be permanently deleted.</p>
                        </v-row>
                        <v-dialog v-model="dialogDeleteAccount" max-width="600">
                            <template v-slot:activator="{ on }">
                                <v-row justify="center" class="pb-2">
                                    <v-btn tile outlined elevation="12" color="red white--text" v-on="on">Delete Account</v-btn>
                                </v-row>
                            </template>
                            <v-card tile elevation="12" style="border: solid 1px red;" max-width="600" class="py-2 px-4">
                                <v-card-title>
                                    <v-row justify="center">
                                        <span class="text-h5 red--text">Delete Account</span>
                                        <span class="pr-5" style="position: absolute; right: 0;">
                                            <v-btn icon color="grey" href="#" @click="dialogDeleteAccount = false">
                                                <font-awesome-icon icon="times" fixed-width style="font-size: 16px;"/>
                                            </v-btn>
                                        </span>
                                    </v-row>
                                </v-card-title>
                                <v-divider class="mx-5"></v-divider>
                                <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" class="pa-0">
                                        <p class="text-body-1 font-weight-light text-center">All account data will be permanently deleted.</p>
                                    </v-col>
                                </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row justify="center">
                                        <v-btn color="red white--text" tile elevation="6" @click="deleteAccount">Delete</v-btn>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </v-col>
            </v-row>
        </RequireLogin>
    </v-container>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import RequireLogin from '@/components/RequireLogin.vue';
import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
import { Client } from '@/client';

const client = new Client();

export default {
    components: {
        RequireLogin,
    },
    data() {
        return {
            snackbarRememberDevice: false,
            snackbarLockTimeUpdated: false,
            snackbarAccountDeleteFailed: false,
            dialogDeleteAccount: false,
            // nameRules: [
            //     (v) => (typeof v === 'string' && v.length >= 1) || 'Must not be empty',
            // ],
            nameRules: [
                (v) => isValidName(v) || 'Enter your name',
            ],
            emailRules: [
                (v) => !v || isValidEmail(v) || 'Enter your email address',
            ],
            phoneRules: [
                (v) => !v || isValidPhone(v) || 'Enter your phone number',
            ],
            snackbarIdentityNameUpdated: false,
            snackbarIdentityEmailUpdated: false,
            editableName: null,
            editableEmail: null,
            defaultSessionIdleExpiresMillis: null,
            sessionExpiresChoices: [
                { text: 'Default', value: 0 },
                { text: '2 min', value: 2 * 60 * 1000 },
                { text: '5 min', value: 5 * 60 * 1000 },
                { text: '10 min', value: 10 * 60 * 1000 },
                { text: '15 min', value: 15 * 60 * 1000 },
                { text: '30 min', value: 30 * 60 * 1000 },
                { text: '45 min', value: 45 * 60 * 1000 },
                { text: '1 hour', value: 1 * 60 * 60 * 1000 },
                { text: '2 hours', value: 2 * 60 * 60 * 1000 },
                { text: '4 hours', value: 4 * 60 * 60 * 1000 },
                { text: '8 hours', value: 8 * 60 * 60 * 1000 },
                { text: '12 hours', value: 12 * 60 * 60 * 1000 },
                { text: '1 day', value: 1 * 24 * 60 * 60 * 1000 },
                { text: '7 days', value: 7 * 24 * 60 * 60 * 1000 },
            ],
        };
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        name: {
            get() {
                if (this.user.name) {
                    return this.user.name;
                }
                return '';
            },
            set(value) {
                this.$store.dispatch('editUser', { name: value });
            },
        },
        email: {
            get() {
                if (this.user.email) {
                    return this.user.email;
                }
                return '';
            },
            set(value) {
                // TODO: need email verification first ... let server set it after user clicks link ... server should reject editUser { email } , it should make available a workflow for changing email that a client can initiate
                this.$store.dispatch('editUser', { email: value });
            },
        },
        isIdentityNameComplete() {
            return this.user
            && this.editableName
            && this.editableName !== this.user.name;
        },
        isIdentityEmailComplete() {
            return this.user
            && this.editableEmail
            && this.editableEmail !== this.user.email;
        },
        sessionIdleExpiresMillis: {
            get() {
                return this.user.sessionIdleExpiresMillis;
            },
            set(value) {
                this.$store.dispatch('editUser', { sessionIdleExpiresMillis: value });
                this.snackbarLockTimeUpdated = true;
            },
        },
    },
    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        name(val) {
            this.editableName = val;
        },
        email(val) {
            this.editableEmail = val;
        },
        dialogDeleteAccount(val) {
            if (!val) {
                this.closeDialogDeleteAccount();
            }
        },
    },
    methods: {
        init() {
            console.log('Preferences.vue: init');
        },
        editName() {
            this.name = this.editableName;
            this.snackbarIdentityNameUpdated = true;
        },
        editEmail() {
            this.email = this.editableEmail;
            this.snackbarIdentityEmailUpdated = true;
        },
        async deleteAccount() {
            this.$store.commit('loading', { deleteAccount: true });
            const response = await client.user.delete();
            if (response.isDeleted) {
                await this.$store.dispatch('logout');
                this.$router.push('/');
                console.log('account.vue: deleteAccount success: %o', response);
            } else {
                console.log('account.vue: deleteAccount failed: %o', response);
                this.snackbarAccountDeleteFailed = true;
            }
            this.closeDialogDeleteAccount();
            this.$store.commit('loading', { deleteAccount: false });
        },
        closeDialogDeleteAccount() {
            this.dialogDeleteAccount = false;
        },
    },
    mounted() {
        // TODO: should be inside init()  after ready()
        this.editableName = this.name;
        this.editableEmail = this.email;
    },
};
</script>
